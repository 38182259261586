
export function dig(obj, ...propNames) {
    var last = propNames.pop()

    var current = obj
    for (var i = 0, limit = propNames.length; i < limit; i++) {
        var prop = propNames[i]
        if (typeof current === 'undefined') {
            break
        }

        if (!(prop in current)) {
            break
        }

        if (!(current = current[prop])) {
            break
        }

        if (typeof current !== 'object') {
            break
        }
    }
    if (typeof last === 'object' && ('default' in last)) {
        return current || last['default']
    }
    if (current !== null && typeof current === 'object') {
        current = current[last]
    }

    return current
}
