import React from 'react';
import { serializeError } from '../../Utils/common';
import { Alert, Button } from 'react-bootstrap';
import './style.scss';

export function ErrorPage({ error }) {
    const serializedError = serializeError(error);

    function handleReloadClick() {
        window.location.reload();
    }

    return (
        <div className='error-page'>
            <section className='error-page-card'>
                <Alert variant='danger'>
                    <Alert.Heading>Beklemediğimiz bir durum ile karşılaştık.</Alert.Heading>
                    <p className='error-page-card-error'>{serializedError}</p>
                    <hr />
                    <div className='d-flex justify-content-end'>
                        <Button onClick={handleReloadClick} variant='danger'>
                            Sayfayı Yenile
                        </Button>
                    </div>
                </Alert>
            </section>
        </div>
    );
}
