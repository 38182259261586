import React, { Component } from 'react';
import { Card, Col, Form, Container, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import './style.scss';
import { withRouter, Redirect } from 'react-router-dom';
import Logo from '../../components/Logo';
import { AxiosInstance, LoginRequest, UserDetails } from '../../Helper/api';
import { dig } from '../../Helper/commons';
import setAuth from '../../Helper/setAuth';
import UButton from '../../components/UButton';
import { serializeError } from '../../Utils/common';
class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            login: {
                pending: false,
                success: false,
                error: false,
            },
            errorMessages: '',
        };
        this.Submit = this.Submit.bind(this);
    }
    Submit = async (fields, sets) => {
        const { email, password } = fields;

        if (email !== '' && password !== '') {
            this.setState(
                {
                    login: {
                        ...this.state.login,
                        pending: true,
                        success: false,
                        error: false,
                    },
                },
                () => {
                    LoginRequest(email, password)
                        .then((usr) => {
                            let user = { ...usr };
                            setAuth(user.access_token);
                            UserDetails()
                                .then(async (details) => {
                                    if (!details.IsSysAdmin) {
                                        this.setState({
                                            login: {
                                                ...this.state.login,
                                                pending: false,
                                                error: true,
                                            },
                                            errorMessages: 'You have no login permition.',
                                        });
                                        return;
                                    }
                                    this.setState(
                                        {
                                            login: {
                                                ...this.state.login,
                                                pending: false,
                                                success: true,
                                            },
                                        },
                                        async () => {
                                            user.details = details;
                                            await localStorage.setItem(
                                                'user',
                                                JSON.stringify(user),
                                            );
                                            setTimeout(() => {
                                                this.setState({
                                                    login: {
                                                        ...this.state.login,
                                                        pending: false,
                                                        success: false,
                                                        error: false,
                                                    },
                                                    errorMessages: '',
                                                });
                                            }, 2000);
                                        },
                                    );
                                })
                                .catch((err) => {
                                    this.setState({
                                        login: {
                                            ...this.state.login,
                                            pending: false,
                                            error: true,
                                        },
                                        errorMessages: serializeError(err),
                                    });
                                });
                        })
                        .catch((err) => {
                            this.setState({
                                login: {
                                    ...this.state.login,
                                    pending: false,
                                    error: true,
                                },
                                errorMessages: serializeError(err),
                            });
                        });
                    // localStorage.setItem('Token', JSON.stringify(fields))
                    // this.props.history.push('/');
                },
            );
        }
    };
    render() {
        const user = JSON.parse(localStorage.getItem('user'));
        if (
            user &&
            dig(user, 'user', 'details') &&
            AxiosInstance.defaults.headers.common['Authorization']
        ) {
            return <Redirect to='/' />;
        }

        return (
            <Container id='Login'>
                <div className='Logo'>
                    <Logo />
                </div>
                <Card>
                    <Card.Header>Login</Card.Header>
                    <Card.Body>
                        <Formik
                            onSubmit={this.Submit}
                            initialValues={{
                                email: '',
                                password: '',
                            }}>
                            {({
                                handleSubmit,
                                handleChange,
                                handleBlur,
                                values,
                                touched,
                                isValid,
                                errors,
                            }) => {
                                return (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Row>
                                            <Form.Group
                                                as={Col}
                                                md='12'
                                                controlId='validationFormik01'>
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control
                                                    type='text'
                                                    name='email'
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    // isValid={touched.email && !errors.email}
                                                />
                                            </Form.Group>
                                            <Form.Group
                                                as={Col}
                                                md='12'
                                                controlId='validationFormik02'>
                                                <Form.Label>Password</Form.Label>
                                                <Form.Control
                                                    type='password'
                                                    name='password'
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    // isValid={touched.password && !errors.password}
                                                />
                                            </Form.Group>
                                        </Row>
                                        <UButton
                                            className='Primary'
                                            icon='loadingbutton'
                                            type='submit'
                                            value={'Login'}
                                            pending={this.state.login.pending}
                                            error={this.state.login.error}
                                            success={this.state.login.success}
                                        />
                                    </Form>
                                );
                            }}
                        </Formik>
                        {this.state.login.error && (
                            <p className='text-danger'>{this.state.errorMessages}</p>
                        )}
                    </Card.Body>
                </Card>
            </Container>
        );
    }
}

export default withRouter(Login);
