import React, { Component } from 'react';
import './style.scss'
class UButton extends Component {
    render() {
        return (
            <button type={this.props.type} className={`uButton ${this.props.className} ${this.props.pending ? 'loading disabled' : ''} ${this.props.success ? 'success' : ''} ${this.props.error ? 'error' : ''}`}
                onClick={this.props.onClick}
                disabled={this.props.disabled}
                style={this.props.style}
                data-tut={this.props.dataTut}>
                {this.props.icon ? <span className={`icon ${this.props.icon}`}></span> : null}
                {this.props.iconPath ? <img className={`icon ${this.props.icon}`} src={process.env.PUBLIC_URL + this.props.iconPath} alt={`${this.props.icon}_icon`}></img> : null}
                {this.props.value ? <span className="buttontext">{this.props.value}</span> : null}
            </button>
        )
    }
}

export default UButton;