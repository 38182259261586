import React, { Component, Suspense } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import './App.scss';
// import Main from './Container/Main'
import Login from './pages/Login';
import LoginContainer from './Container/LoginContainer';
import { ErrorBoundary } from './components/ErrorBoundary';
const Main = React.lazy(() => import('./Container/Main'));
export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <ErrorBoundary>
                <Suspense fallback={<div>Loading...</div>}>
                    <BrowserRouter>
                        <Switch>
                            <Route path='/Login' component={LoginContainer(Login)} />
                            <Route path='/' component={Main} />
                        </Switch>
                    </BrowserRouter>
                </Suspense>
            </ErrorBoundary>
        );
    }
}
