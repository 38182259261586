import React from 'react';
import { ErrorPage } from '../../pages/ErrorPage';

export class ErrorBoundary extends React.Component {
    state = {
        hasError: false,
        error: { message: '', stack: '' },
        info: { componentStack: '' },
    };

    static getDerivedStateFromError = (error) => {
        return { hasError: true };
    };

    componentDidCatch = (error, info) => {
        this.setState({ error, info });
    };

    render() {
        const { hasError, error } = this.state;
        const { children } = this.props;

        return hasError ? <ErrorPage error={error.message} /> : children;
    }
}
