import axios from 'axios';

export const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
};

export function serializeError(error) {
    if (typeof error === 'string') {
        return error;
    }

    if (axios.isAxiosError(error)) {
        return error.message;
    }

    if (error instanceof Error) {
        return error.message;
    }

    return JSON.stringify(error);
}
