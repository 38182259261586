import qs from 'qs';
import setAuth from './setAuth';
import { axiosBuilder } from '@sarpere/uxios';

export const apiBase = 'https://api.udentify.co';

const resolveToken = (loginResponse) => {
    return loginResponse.data.access_token;
};
const RefreshTokenRequest = async (_axios) => {
    const Account = JSON.parse(localStorage.getItem('user') || '{}');
    let data = qs.stringify({
        grant_type: 'refresh_token',
        refresh_token: Account.refresh_token,
    });
    const res = await _axios.post(apiBase + '/Token', data);
    if (res) {
        localStorage.setItem(
            'user',
            JSON.stringify({
                ...Account,
                ...res.data,
            }),
        );
        setAuth(res.data.access_token);
    }
    return res;
};
const SetAuthHeader = (axiosInstance) => (method) => {
    // setAuth(method());
};
export const AxiosInstance = axiosBuilder
    .initConfig({
        baseURL: apiBase,
        WithCredentials: false,
        refreshTokenUrl: '/Token',
        resolveToken,
        RefreshTokenRequest,
        setAuthHeader: SetAuthHeader,
    })
    .getInstance();
if (localStorage.user) {
    const user = JSON.parse(localStorage.user);
    if (user.access_token) {
        setAuth(user.access_token);
    } else {
        setAuth(false);
    }
}
// axios.interceptors.response.use(
//   function (res) {
//     if (
//       !axios.defaults.headers.common["Authorization"] &&
//       res.config.url.split("/")[res.config.url.split("/").length - 1] !==
//         "Token"
//     ) {
//       return Promise.reject(false);
//     }

//     if (
//       res.data.Success ||
//       res.data.access_token ||
//       res.headers["content-type"] === "image/jpg" ||
//       res.data.data
//     ) {
//       return res;
//     }
//     return Promise.reject(res.data.Message);
//   },
//   function (error) {
//     if (dig(error.response, "data", "error_description"))
//       return Promise.reject(error.response.data.error_description);
//     return Promise.reject(error.response.data.error_description);
//   }
// );
export async function LoginRequest(username, password) {
    let data = qs.stringify({
        grant_type: 'password',
        username: username,
        password: password,
    });

    const res = await AxiosInstance.post(`/Token`, data);
    return Promise.resolve(res);
}

export async function UserDetails() {
    const res = await AxiosInstance.get(`/User/Details`);
    return Promise.resolve(res);
}

export async function GetNextPort() {
    const res = await AxiosInstance.get('/Mqtt/GetNextPort');
    return Promise.resolve(res);
}

/**
 * @param {BoardId} boardId
 * @param {number} port
 * @description Open SSH tunnel to a board
 * @returns Promise
 */
export async function SSHTunnel(boardId, port) {
    return AxiosInstance.post(`/Boards/${boardId}/SSHTunnel/${port}`).then((res) => res);
}
