import { AxiosInstance } from "./api";

export default function setAuth(token) {
  if (token) {
    AxiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return Promise.resolve(true);
  } else {
    delete AxiosInstance.defaults.headers.common["Authorization"];
    return Promise.reject(false);
  }
}
